import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import LeafRain from "./components/LeafRain";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Nav from "react-bootstrap/Nav";
import "./App.css";

function App() {
  const projectsRef = useRef(null); // Create the ref here

  const scrollToProject = () => {
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToSkills = () => {
    const contactElement = document.getElementById("skills");
    contactElement.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToAbout = () => {
    const contactElement = document.getElementById("about");
    contactElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div id="app">
      <LeafRain />
      <Nav className=" navbar">
        <Nav.Link onClick={scrollToAbout} className="navbar-item">
          About
        </Nav.Link>
        <Nav.Link
          onClick={scrollToSkills}
          className="navbar-item"
          style={{ cursor: "pointer" }}
        >
          Skills
        </Nav.Link>
        <Nav.Link
          onClick={scrollToProject}
          className="navbar-item"
          style={{ cursor: "pointer" }}
        >
          Projects
        </Nav.Link>
      </Nav>
      <div className="container">
        <Home />
        <Skills />
        <Projects ref={projectsRef} />
        <Contact />
      </div>
      <div className="end-message">
        <p>Thank you for visiting my portfolio.</p>
      </div>
      <footer> </footer>
    </div>
  );
}

export default App;
