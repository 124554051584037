import React, { useState, useEffect, useRef, forwardRef } from "react";
import tip from "../images/tip-calculator.png";
import todo from "../images/todo.png";
import translation from "../images/translation.png";
import weather from "../images/weather.png";
import calculator from "../images/calculator.png";
import "./Projects.css";

const projectList = [
  {
    img: tip,
    name: "Tip-Calculator",
    description:
      "A small project from FrontEndMentor.  You and your friends can split the tip using this calculator.",
    link: "https://happy-villani-b90ce3.netlify.app",
    code: "https://github.com/saki1994/tip-calculator",
  },
  {
    img: todo,
    name: "ToDo App",
    description:
      "A project from FrontEndMentor. A mock website to allow user add and keep track of their task.",
    link: "https://stupefied-brahmagupta-076b76.netlify.app/",
    code: "https://github.com/saki1994/react-todo-app",
  },
  {
    img: weather,
    name: "Weather App",
    description:
      "A project which helped me understand API even more. You can see the current weather and see the 5 days forecast.",
    link: "https://weather-saki.herokuapp.com/",
    code: "https://github.com/saki1994/weather-app",
  },
  {
    img: calculator,
    name: "Calculator",
    description:
      "A calculator project that helped me familiarize with DOM. The design is copied from Samsung Calculator.",
    link: "https://dreamy-leavitt-c89b43.netlify.app",
    code: "https://github.com/saki1994/Calculator",
  },
  {
    img: translation,
    name: "Translation Website",
    description:
      "This is my very first live website. A landing page with a little animation added, I also used EmailJS so the user can receive email from future customer.",
    link: "https://suspicious-wescoff-bfa9be.netlify.app",
    code: "https://github.com/saki1994/translation-profile",
  },
];

const Projects = forwardRef((props, forwardedRef) => {
  const [isVisible, setIsVisible] = useState(false);
  const [slideIn, setSlideIn] = useState(false);
  const internalRef = useRef(null);
  const ref = forwardedRef || internalRef; // Use forwardedRef if it exists, otherwise fall back to internalRef

  // This function will calculate the delay based on the index of the project
  const getAnimationDelay = (index) => {
    return `${index * 0.3}s`; // 0.2s delay between each project
  };

  const checkVisibility = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const newIsVisible = rect.top < window.innerHeight * 0.7;
      // Trigger the slide-in animation when the section becomes visible
      if (newIsVisible && !isVisible) {
        setSlideIn(true);
      }
      // Trigger the slide-out animation when the section goes out of view
      if (!newIsVisible && isVisible) {
        setSlideIn(false);
      }
      setIsVisible(newIsVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    checkVisibility();
    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, [isVisible]);

  // Apply the 'visible' class and animation classes based on the state
  const projectClass = isVisible ? "project visible" : "project";
  const animationClass = slideIn ? "slide-in" : "slide-out";

  return (
    <div ref={ref} className={projectClass}>
      <h3>My Projects</h3>
      {projectList.map((project, index) => (
        <div
          key={project.name}
          className={`project-content  ${animationClass}`}
          style={{ animationDelay: getAnimationDelay(index) }}
        >
          <div className="img-box">
            <img src={project.img} alt={project.name} />
          </div>
          <h6>{project.name}</h6>
          <div className="btn-box">
            <button>
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                Project
              </a>
            </button>
            <button>
              <a href={project.code} target="_blank" rel="noopener noreferrer">
                Code
              </a>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
});

export default Projects;
