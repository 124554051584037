import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "./Skills.css";
const Skills = () => {
  const [skillJson, setSkillJson] = useState({});

  useEffect(() => {
    // Define an async function that will fetch your data
    const fetchSkills = async () => {
      try {
        // Fetch the JSON data from the 'skills.json' file
        const response = await fetch("./skills.json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Parse the JSON data and set it to the skillJson state
        const data = await response.json();
        setSkillJson(data);
      } catch (error) {
        // Handle any errors here
        console.error("Could not fetch skills:", error);
      }
    };

    // Call the function
    fetchSkills();
  }, []); // The empty dependency array ensures this effect runs once on mount

  return (
    <div xs={1} md={3} id="skills" className="g-3 skill-wrapper">
      {Object.entries(skillJson).map(([category, skills]) => (
        <Card className="skill-card">
          <Card.Header>
            <Card.Title className="skill-header">
              {splitCamelCaseToString(category)}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {skills.map((skill, index) => (
              <p key={index} className="d-flex align-items-center mb-2">
                {skill}
              </p>
            ))}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

// Function to split camel case to title
function splitCamelCaseToString(s) {
  return s.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export default Skills;
