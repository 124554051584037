import "./LeafRain.css"; // Import your CSS

function LeafRain() {
  // Assuming you want 15 leaves based on the original code
  const numberOfLeaves = 15;
  const leaves = Array.from({ length: numberOfLeaves }, (_, index) => (
    <i key={index}></i>
  ));

  return <div id="leaves">{leaves}</div>;
}

export default LeafRain;
