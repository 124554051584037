import React from "react";
import { FaGithub, FaTwitter, FaLinkedin } from "react-icons/fa";
import "./Contact.css";

const socialLinks = [
  { icon: FaGithub, href: "https://github.com/saki1994", ariaLabel: "Github" },
  {
    icon: FaTwitter,
    href: "https://twitter.com/SAtici12",
    ariaLabel: "Twitter",
  },
  {
    icon: FaLinkedin,
    href: "https://www.linkedin.com/in/sakirumatsu/",
    ariaLabel: "LinkedIn",
  },
];

const Contact = () => {
  return (
    <div className="floating-container">
      {socialLinks.map((link, index) => (
        <a
          key={index}
          className="icon-link"
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={link.ariaLabel}
        >
          <link.icon className="icon-style" />
        </a>
      ))}
    </div>
  );
};

export default Contact;
