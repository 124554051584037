import React from "react";
import me from "../images/photo.jpg";
import leftLeaf from "../images/leaves/2.png";
import rightLeaf from "../images/leaves/3.png";
import "./Home.css";

const Home = () => {
  return (
    <main id="about" className="home">
      <div className="row">
        {/* Profile Image and Leaf Decorations */}
        <div className="col-lg-6 col-md-12 col-sm-12 image-container">
          <img className="me" src={me} alt="Main" />
          <img src={leftLeaf} alt="Leaf Upper Left" className="upper-left" />
          <img
            src={rightLeaf}
            alt="Leaf Bottom Right"
            className="bottom-right"
          />
        </div>

        {/* Introduction Text */}
        <div className="col-lg-6 col-md-12 col-sm-12 ">
          <p>Thank you for visiting my portfolio.</p>
          <p>
            My name is <strong>Sarah Atici</strong>, and I have been working as
            a Web Developer for 3 years now. My journey in the tech industry
            started out of curiosity when a client reported a 404 error on my
            company's website. That simple Google search led me to delve deeper
            into Web Development.
          </p>

          <p>
            I strive to stay positive and spread good energy to those around me.
            I love watching leaves fall while sipping hot coffee, and I often
            turn to Stack Overflow for answers.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Home;
